// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Onest');
@import url('https://fonts.googleapis.com/css?family=Calibri');
@import "./assets/css/tuula/tuula-ui-color-palette";


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$c4p-portal-docgen-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      density: (scale: 0,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($c4p-portal-docgen-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($c4p-portal-docgen-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($c4p-portal-docgen-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}



/****************/
.mat-mdc-dialog-surface {
  border-radius: unset !important;
}

/***************/
.tool-bar-container {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-right: 10px;
}


.tool-bar-item {
  display: inline-block;
  text-align: center;
  margin: 2px;
  width: 40px;
  padding-top: 5px;
  height: 28px;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.11);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.tool-bar-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.21);
}

.tool-bar-icon {
  display: inline;
  vertical-align: middle;
  font-size: 22px;
  color: var(--primary-color-500);
}

.tool-bar-text {
  font-size: 11px;
  text-align: center;
  color: rgb(121, 121, 121);
}


.custom-modalbox {

}

.mat-mdc-dialog-container {
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
  background: #fff;
  color: rgba(0, 0, 0, .87);
}



.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 100% !important;
  min-width: auto !important;
}

.form-title {
  font-size: 14px;
  height: 20px;
  padding: 10px;
  border-bottom: lightgray;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  color: var(--white);
  background-color: var(--primary-color-500) !important;
  margin-bottom: 10px;
}

.form-subtitle {
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: lightgray;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  color: grey;
  border: none;
}

.form-input {
  font-size: 13px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.form-content {
  padding: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(188, 228, 243);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color-500);
}

.form-align-left {
  display: inline-block;
  width: 50%;
  text-align: left;
}

.form-align-right {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.form-label {
  font-size: 13px;
  color: darkgray;
  margin-right: 4px;
}

.form-value {
  font-size: 13px;
  color: rgb(54, 54, 54);
}

.form-divider {
  border-bottom: 1px solid lightgray;
}

.spacer {
  height: 24px;
}


.action_ok {
  display: inline-block;
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 45%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--primary-color-500);
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: width 0.5s;
  border-radius: 4px;
  margin: 5px;
  float: right;
}

.action_ok:hover {
  background-color: var(--primary-color-400);
}

.action_cancel {
  display: inline-block;
  text-align: center;
  width: 45%;
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--primary-color-800);
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-color-200);
  margin: 5px;
  float: left;
}

.action_cancel:hover {
  background-color: var(--gray-color-300);
}

.tuula-badge {
  border-radius: 16px !important;
  padding: 2px 15px;
  mix-blend-mode: multiply;
  font-size: 10px;
  font-weight: 400;
  word-wrap: normal;
  font-family: Onest !important;
}

.label-status-active {
  @extend .tuula-badge;
  background-color: var(--winter-wizard-color-50);
  border: 1px solid var(--winter-wizard-color-200);
  color: var(--winter-wizard-color-700);
}

.label-status-draft {
  @extend .tuula-badge;
  background-color: var(--warning-color-50);
  color: var(--warning-color-700);
  border: 1px solid var(--warning-color-200);
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.A4 {
  width: 100%;
  margin: auto;
  max-width: 85%;
}

.froalaTableBlack thead tr th,
.froalaTableBlack tbody tr td {
  border-style: solid;
  border-color: black;
}

.froalaTableTransparent thead tr th,
.froalaTableTransparent tbody tr td {
  border-style: none;
}

.froalaTableOrange thead tr th,
.froalaTableOrange tbody tr td {
  border-style: solid;
  border-color: var(--primary-color-500);
}

.froalaTableBlue thead tr th,
.froalaTableBlue tbody tr td {
  border-style: solid;
  border-color: var(--primary-color-400);
}

/****Table***/
.mat-mdc-table {
  overflow: auto;
  font-family: var(--tuula-font-family) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: lightgrey !important;
}

.mat-mdc-cell,
.mat-mdc-header-cell,
.mat-mdc-footer-cell {
  padding-top: 0 !important;
}

.mat-mdc-header-cell {
  background-color: var(--gray-color-100) !important;
  color: var(--table-header-font-color) !important;
  font-size: 12px;
  font-weight: 500;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-cell {
  color: var(--form-font-color);
  font-family: var(--tuula-font-family) !important;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-sort-header {
  color: var(--table-header-font-color);
  padding-top: 5px;
}

.mat-mdc-header-row {
  min-height: 35px;
  border-color: var(--form-line-color);
  background-color: var(--table-header-bg-color);
}

.mat-mdc-row {
  background-color: var(--form-bg-color) !important;
  border-color: var(--light-border) !important;
  color: var(--form-font-color) !important;
}

.mat-mdc-row:hover {
  background-color: var(--light-border) !important;
}

.mat-mdc-row.highlighted {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-left-width: 5px !important;
  border-left-color: var(--primary-color-500) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}

.mat-mdc-row.highlighted-non-selection {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}

/****Table***/




.mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 14px !important;
  font-family: var(--tuula-font-family);

}


.mat-mdc-form-field-infix {
  --mat-form-field-container-height: 38px !important;
  --mat-form-field-filled-with-label-container-padding-top: 16px !important;
  --mat-form-field-filled-with-label-container-padding-bottom: 5px !important;
}

.mat-mdc-select-value {
  font-size: 13px !important;
}

.mat-mdc-option {
  font-size: 13px !important;
}

.mat-mdc-tooltip {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
  line-height: 15px;

}

.mat-expansion-panel-body {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
  width: 250px !important;
}


/*scroll*/
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--white);
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-color-300);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-color-400);
}




.mat-mdc-form-field {
  .mat-mdc-input-element {
    color: black;
    font-size: 13px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: darkgray;
    font-size: 13px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    background-color: lightgrey;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    background-color: lightgrey;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-required-marker {
    color: lightgrey;
  }
}

.mat-mdc-form-field.mat-focused {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: lightgray;
    font-size: 13px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    background-color: var(--primary-color-500);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-required-marker {
    color: var(--primary-color-500);
  }

  .mat-mdc-input-element {
    color: black;
    font-size: 13px;
  }
}

.mat-mdc-tooltip {
  color: white;
  background-color: #5c5c5c;
  font-size: 11px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox .mat-checkbox-frame {
  border-color: lightgray;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--gray-color-400) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--primary-color-50) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: var(--mdc-filled-text-field-label-text-color, var(--mat-app-on-surface-variant)) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primary-color-500) !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  border-color: gray !important;
  background-color: gray !important;
  margin-top: -6px;
}

.cdk-overlay-pane{
  max-height: 100vh !important;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color:  var(--white) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color-600) !important;

  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-handle-color: var(--white) !important;
  --mdc-switch-selected-pressed-handle-color: var(--white) !important;

  --mdc-switch-unselected-handle-color: var(--white) !important;
  --mdc-switch-unselected-focus-handle-color: var(--white) !important;
  --mdc-switch-unselected-hover-handle-color: var(--white) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--white) !important;


  --mdc-switch-selected-focus-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-track-color: var(--primary-color-600) !important;

  --mdc-switch-unselected-focus-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-hover-track-color: var(--gray-color-100)!important;
  --mdc-switch-unselected-pressed-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-track-color: var(--gray-color-100) !important;

  --mdc-switch-selected-icon-color:   var(--white) !important;/*tick color*/
  --mdc-switch-unselected-icon-color:  var(--gray-color-100) !important; /*minus color*/

  --mat-switch-track-outline-color: var(--gray-color-300) !important;
  --mat-switch-disabled-unselected-track-outline-color: var(--gray-color-200) !important;
  --mat-switch-label-text-color: var(--gray-color-900) !important;


  --mdc-switch-track-width: 50px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-shape: 9999px;

  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;

  --mdc-switch-state-layer-size: 24px;
}

.mat-mdc-option.mdc-list-item {
  background-color: #fff !important;
}
.mat-mdc-option.mdc-list-item:hover {
  background-color: #f5f5f5 !important;
}
