/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Onest");
@import url("https://fonts.googleapis.com/css?family=Calibri");
:root {
  --black: #000;
  --white: #fff;
  --primary-color-25: #E6E5F2;
  --primary-color-50: #DEDDE9;
  --primary-color-100: #B7B6C5;
  --primary-color-200: #9291A8;
  --primary-color-300: #6E6D8B;
  --primary-color-400: #4A496F;
  --primary-color-500: #262353;
  --primary-color-600: #201E44;
  --primary-color-700: #191836;
  --primary-color-800: #131228;
  --primary-color-900: #0D0C1C;
  --primary-color-950: #080711;
  --error-color-25: #FFFBFA;
  --error-color-50: #FEF3F2;
  --error-color-100: #FEE4E2;
  --error-color-200: #FECDCA;
  --error-color-300: #FDA29B;
  --error-color-400: #F97066;
  --error-color-500: #F04438;
  --error-color-600: #D92D20;
  --error-color-700: #B42318;
  --error-color-800: #912018;
  --error-color-900: #7A271A;
  --error-color-950: #55160C;
  --warning-color-25: #FFFCF5;
  --warning-color-50: #FFFAEB;
  --warning-color-100: #FEF0C7;
  --warning-color-200: #FEDF89;
  --warning-color-300: #FEC84B;
  --warning-color-400: #FDB022;
  --warning-color-500: #F79009;
  --warning-color-600: #DC6803;
  --warning-color-700: #B54708;
  --warning-color-800: #93370D;
  --warning-color-900: #7A2E0E;
  --warning-color-950: #4E1D09;
  --success-color-25: #F6FEF9;
  --success-color-50: #ECFDF3;
  --success-color-100: #DCFAE6;
  --success-color-200: #ABEFC6;
  --success-color-300: #75E0A7;
  --success-color-400: #47CD89;
  --success-color-500: #17B26A;
  --success-color-600: #079455;
  --success-color-700: #067647;
  --success-color-800: #085D3A;
  --success-color-900: #074D31;
  --success-color-950: #053321;
  --gray-color-25: #FCFCFD;
  --gray-color-50: #F9FAFB;
  --gray-color-100: #F2F4F7;
  --gray-color-200: #EAECF0;
  --gray-color-300: #D0D5DD;
  --gray-color-400: #98A2B3;
  --gray-color-500: #667085;
  --gray-color-600: #475467;
  --gray-color-700: #344054;
  --gray-color-800: #1D2939;
  --gray-color-900: #101828;
  --gray-color-950: #0C111D;
  --tomato-color-25: #FFEDE9;
  --tomato-color-50: #FEE0DB;
  --tomato-color-100: #FDCBC2;
  --tomato-color-200: #FCB1A3;
  --tomato-color-300: #FC9885;
  --tomato-color-400: #FB7E67;
  --tomato-color-500: #FA654B;
  --tomato-color-600: #D1543F;
  --tomato-color-700: #A74431;
  --tomato-color-800: #7D3225;
  --tomato-color-900: #532119;
  --tomato-color-950: #32140F;
  --winter-wizard-color-25: #F1F9FF;
  --winter-wizard-color-50: #ECF7FF;
  --winter-wizard-color-100: #DFF2FE;
  --winter-wizard-color-200: #CFEBFE;
  --winter-wizard-color-300: #C0E4FE;
  --winter-wizard-color-400: #B0DEFD;
  --winter-wizard-color-500: #A0D7FD;
  --winter-wizard-color-600: #85B3D3;
  --winter-wizard-color-700: #6B8FA9;
  --winter-wizard-color-800: #506B7E;
  --winter-wizard-color-900: #354854;
  --winter-wizard-color-950: #202B33;
  --powder-blue-color-25: #FAFFFD;
  --powder-blue-color-50: #F1FBF8;
  --powder-blue-color-100: #E8F9F4;
  --powder-blue-color-200: #DDF5EE;
  --powder-blue-color-300: #D2F2E8;
  --powder-blue-color-400: #C6EFE3;
  --powder-blue-color-500: #BBECDD;
  --powder-blue-color-600: #9CC5B8;
  --powder-blue-color-700: #7D9D93;
  --powder-blue-color-800: #5D766E;
  --powder-blue-color-900: #3E4F4A;
  --powder-blue-color-950: #252F2C;
  --pale-lavender-color-25: #FBF9FF;
  --pale-lavender-color-50: #F8F3FF;
  --pale-lavender-color-100: #F3EBFE;
  --pale-lavender-color-200: #EDE1FE;
  --pale-lavender-color-300: #E7D8FE;
  --pale-lavender-color-400: #E1CEFD;
  --pale-lavender-color-500: #DBC4FD;
  --pale-lavender-color-600: #B6A3D3;
  --pale-lavender-color-700: #9283A9;
  --pale-lavender-color-800: #6D627E;
  --pale-lavender-color-900: #494154;
  --pale-lavender-color-950: #2C2733;
  --tuula-font-family: Onest, "Calibri", sans-serif !important;
  --form-title-bg-color: #f3f4f7;
  --form-title-font-color: #303030;
  --form-title-line-color: #e4e4e4;
  --card-bg-color: #f3f4f7;
  --card-line-color: #e4e4e4;
  --card-shade-bg-color: #f5f6f9;
  --card-shade-bg-color2: #f7f8fb;
  --card-shade-font-color: #272727;
  --card-shade-line-color: #e4e4e4;
  --table-header-bg-color: #fbfcfd;
  --table-header-font-color: #404040;
  --form-bg-color: #ffffff;
  --form-font-color: #606060;
  --form-line-color: #efefef;
  --form-shade-bg-color: #f9fafc;
  --form-dynamic-bg-color: #fcfcfc;
  --master-bg-color: #f9fafc;
  --master-font-color: #272727;
  --master-line-color: #eaeaea;
  --top-bg-color: #ffffff;
  --top-font-color: #272727;
  --dark-border: #767676;
  --light-border: #f7f8fb;
  --warning-border: #e03e0d;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

:root {
  --mat-app-background-color: #faf9fd;
  --mat-app-text-color: #1a1b1f;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: rgba(26, 27, 31, 0.1);
  --mat-option-selected-state-label-text-color: #131c2b;
  --mat-option-label-text-color: #1a1b1f;
  --mat-option-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-option-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-option-selected-state-layer-color: #dae2f9;
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
  --mat-optgroup-label-text-color: #44474e;
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.006rem;
  --mat-optgroup-label-text-weight: 500;
  --mat-full-pseudo-checkbox-selected-icon-color: #005cbb;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-full-pseudo-checkbox-unselected-icon-color: #44474e;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #faf9fd;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005cbb;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(26, 27, 31, 0.38);
  --mdc-elevated-card-container-color: #f4f3f6;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-color: #faf9fd;
  --mdc-outlined-card-outline-color: #c4c6d0;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
  --mat-card-subtitle-text-color: #1a1b1f;
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 1.75rem;
  --mat-card-title-text-size: 1.375rem;
  --mat-card-title-text-tracking: 0;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-tracking: 0.009rem;
  --mat-card-subtitle-text-weight: 500;
  --mdc-linear-progress-active-indicator-color: #005cbb;
  --mdc-linear-progress-track-color: #e0e2ec;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-color: #2f3033;
  --mdc-plain-tooltip-supporting-text-color: #f2f0f4;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #005cbb;
  --mdc-filled-text-field-focus-active-indicator-color: #005cbb;
  --mdc-filled-text-field-focus-label-text-color: #005cbb;
  --mdc-filled-text-field-container-color: #e0e2ec;
  --mdc-filled-text-field-disabled-container-color: rgba(26, 27, 31, 0.04);
  --mdc-filled-text-field-label-text-color: #44474e;
  --mdc-filled-text-field-hover-label-text-color: #44474e;
  --mdc-filled-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-color: #1a1b1f;
  --mdc-filled-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #44474e;
  --mdc-filled-text-field-error-hover-label-text-color: #410002;
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: #44474e;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #1a1b1f;
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-caret-color: #005cbb;
  --mdc-outlined-text-field-focus-outline-color: #005cbb;
  --mdc-outlined-text-field-focus-label-text-color: #005cbb;
  --mdc-outlined-text-field-label-text-color: #44474e;
  --mdc-outlined-text-field-hover-label-text-color: #1a1b1f;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-color: #1a1b1f;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #44474e;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-label-text-color: #410002;
  --mdc-outlined-text-field-outline-color: #74777f;
  --mdc-outlined-text-field-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #1a1b1f;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #410002;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mat-form-field-focus-select-arrow-color: #005cbb;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-state-layer-color: #1a1b1f;
  --mat-form-field-error-text-color: #ba1a1a;
  --mat-form-field-select-option-text-color: #1a1b1f;
  --mat-form-field-select-disabled-option-text-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-leading-icon-color: #44474e;
  --mat-form-field-disabled-leading-icon-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-trailing-icon-color: #44474e;
  --mat-form-field-disabled-trailing-icon-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
  --mat-form-field-error-hover-trailing-icon-color: #410002;
  --mat-form-field-error-trailing-icon-color: #ba1a1a;
  --mat-form-field-enabled-select-arrow-color: #44474e;
  --mat-form-field-disabled-select-arrow-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-select-panel-background-color: #efedf0;
  --mat-select-enabled-trigger-text-color: #1a1b1f;
  --mat-select-disabled-trigger-text-color: rgba(26, 27, 31, 0.38);
  --mat-select-placeholder-text-color: #44474e;
  --mat-select-enabled-arrow-color: #44474e;
  --mat-select-disabled-arrow-color: rgba(26, 27, 31, 0.38);
  --mat-select-focused-arrow-color: #005cbb;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: 0.031rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #efedf0;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-color: #faf9fd;
  --mdc-dialog-subhead-color: #1a1b1f;
  --mdc-dialog-supporting-text-color: #44474e;
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.016rem;
  --mdc-dialog-container-shape: 28px;
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
  --mdc-chip-outline-color: #74777f;
  --mdc-chip-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-chip-focus-outline-color: #44474e;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-chip-elevated-selected-container-color: #dae2f9;
  --mdc-chip-flat-disabled-selected-container-color: rgba(26, 27, 31, 0.12);
  --mdc-chip-focus-state-layer-color: #44474e;
  --mdc-chip-hover-state-layer-color: #44474e;
  --mdc-chip-selected-hover-state-layer-color: #131c2b;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #131c2b;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #44474e;
  --mdc-chip-selected-label-text-color: #131c2b;
  --mdc-chip-with-icon-icon-color: #44474e;
  --mdc-chip-with-icon-disabled-icon-color: #1a1b1f;
  --mdc-chip-with-icon-selected-icon-color: #131c2b;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1b1f;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #44474e;
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: 0.006rem;
  --mdc-chip-label-text-weight: 500;
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
  --mat-chip-trailing-action-state-layer-color: #44474e;
  --mat-chip-selected-trailing-action-state-layer-color: #131c2b;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #1a1b1f;
  --mat-chip-selected-trailing-icon-color: #131c2b;
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #005cbb;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #005cbb;
  --mdc-switch-selected-pressed-state-layer-color: #005cbb;
  --mdc-switch-selected-focus-handle-color: #d7e3ff;
  --mdc-switch-selected-hover-handle-color: #d7e3ff;
  --mdc-switch-selected-pressed-handle-color: #d7e3ff;
  --mdc-switch-selected-focus-track-color: #005cbb;
  --mdc-switch-selected-hover-track-color: #005cbb;
  --mdc-switch-selected-pressed-track-color: #005cbb;
  --mdc-switch-selected-track-color: #005cbb;
  --mdc-switch-disabled-selected-handle-color: #faf9fd;
  --mdc-switch-disabled-selected-icon-color: #1a1b1f;
  --mdc-switch-disabled-selected-track-color: #1a1b1f;
  --mdc-switch-disabled-unselected-handle-color: #1a1b1f;
  --mdc-switch-disabled-unselected-icon-color: #e0e2ec;
  --mdc-switch-disabled-unselected-track-color: #e0e2ec;
  --mdc-switch-selected-icon-color: #001b3f;
  --mdc-switch-unselected-focus-handle-color: #44474e;
  --mdc-switch-unselected-focus-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-focus-track-color: #e0e2ec;
  --mdc-switch-unselected-handle-color: #74777f;
  --mdc-switch-unselected-hover-handle-color: #44474e;
  --mdc-switch-unselected-hover-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-hover-track-color: #e0e2ec;
  --mdc-switch-unselected-icon-color: #e0e2ec;
  --mdc-switch-unselected-pressed-handle-color: #44474e;
  --mdc-switch-unselected-pressed-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-pressed-track-color: #e0e2ec;
  --mdc-switch-unselected-track-color: #e0e2ec;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 1.25rem;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: 0.016rem;
  --mat-switch-label-text-weight: 400;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
  --mdc-radio-disabled-selected-icon-color: #1a1b1f;
  --mdc-radio-disabled-unselected-icon-color: #1a1b1f;
  --mdc-radio-unselected-hover-icon-color: #1a1b1f;
  --mdc-radio-unselected-focus-icon-color: #1a1b1f;
  --mdc-radio-unselected-icon-color: #44474e;
  --mdc-radio-unselected-pressed-icon-color: #1a1b1f;
  --mdc-radio-selected-focus-icon-color: #005cbb;
  --mdc-radio-selected-hover-icon-color: #005cbb;
  --mdc-radio-selected-icon-color: #005cbb;
  --mdc-radio-selected-pressed-icon-color: #005cbb;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mat-radio-ripple-color: #1a1b1f;
  --mat-radio-checked-ripple-color: #005cbb;
  --mat-radio-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-radio-label-text-color: #1a1b1f;
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 1.25rem;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: 0.016rem;
  --mat-radio-label-text-weight: 400;
  --mat-radio-touch-target-display: block;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: #005cbb;
  --mdc-slider-focus-handle-color: #005cbb;
  --mdc-slider-hover-handle-color: #005cbb;
  --mdc-slider-active-track-color: #005cbb;
  --mdc-slider-inactive-track-color: #e0e2ec;
  --mdc-slider-with-tick-marks-inactive-container-color: #44474e;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #1a1b1f;
  --mdc-slider-disabled-handle-color: #1a1b1f;
  --mdc-slider-disabled-inactive-track-color: #1a1b1f;
  --mdc-slider-label-container-color: #005cbb;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #1a1b1f;
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: #005cbb;
  --mat-slider-hover-state-layer-color: rgba(0, 92, 187, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 92, 187, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
  --mat-menu-item-label-text-color: #1a1b1f;
  --mat-menu-item-icon-color: #44474e;
  --mat-menu-item-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-menu-container-color: #efedf0;
  --mat-menu-divider-color: #e0e2ec;
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.006rem;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-base-elevation-level: 2;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #d7e3ff;
  --mdc-list-list-item-disabled-state-layer-color: #1a1b1f;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #1a1b1f;
  --mdc-list-list-item-supporting-text-color: #44474e;
  --mdc-list-list-item-leading-icon-color: #44474e;
  --mdc-list-list-item-trailing-supporting-text-color: #44474e;
  --mdc-list-list-item-trailing-icon-color: #44474e;
  --mdc-list-list-item-selected-trailing-icon-color: #005cbb;
  --mdc-list-list-item-disabled-label-text-color: #1a1b1f;
  --mdc-list-list-item-disabled-leading-icon-color: #1a1b1f;
  --mdc-list-list-item-disabled-trailing-icon-color: #1a1b1f;
  --mdc-list-list-item-hover-label-text-color: #1a1b1f;
  --mdc-list-list-item-focus-label-text-color: #1a1b1f;
  --mdc-list-list-item-hover-state-layer-color: #1a1b1f;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: #1a1b1f;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: #dae2f9;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
  --mat-paginator-container-text-color: #1a1b1f;
  --mat-paginator-container-background-color: #faf9fd;
  --mat-paginator-enabled-icon-color: #44474e;
  --mat-paginator-disabled-icon-color: rgba(26, 27, 31, 0.38);
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-color: #005cbb;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: #e0e2ec;
  --mat-tab-header-pagination-icon-color: #1a1b1f;
  --mat-tab-header-inactive-label-text-color: #1a1b1f;
  --mat-tab-header-active-label-text-color: #1a1b1f;
  --mat-tab-header-active-ripple-color: #1a1b1f;
  --mat-tab-header-inactive-ripple-color: #1a1b1f;
  --mat-tab-header-inactive-focus-label-text-color: #1a1b1f;
  --mat-tab-header-inactive-hover-label-text-color: #1a1b1f;
  --mat-tab-header-active-focus-label-text-color: #1a1b1f;
  --mat-tab-header-active-hover-label-text-color: #1a1b1f;
  --mat-tab-header-active-focus-indicator-color: #005cbb;
  --mat-tab-header-active-hover-indicator-color: #005cbb;
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-divider-height: 1px;
  --mdc-checkbox-disabled-selected-checkmark-color: #faf9fd;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: #005cbb;
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #005cbb;
  --mdc-checkbox-selected-hover-icon-color: #005cbb;
  --mdc-checkbox-selected-icon-color: #005cbb;
  --mdc-checkbox-unselected-focus-icon-color: #1a1b1f;
  --mdc-checkbox-unselected-hover-icon-color: #1a1b1f;
  --mdc-checkbox-unselected-icon-color: #44474e;
  --mdc-checkbox-selected-focus-state-layer-color: #005cbb;
  --mdc-checkbox-selected-hover-state-layer-color: #005cbb;
  --mdc-checkbox-selected-pressed-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-pressed-state-layer-color: #005cbb;
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 1.25rem;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 400;
  --mat-checkbox-touch-target-display: block;
  --mdc-text-button-label-text-color: #005cbb;
  --mdc-text-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: 0.006rem;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: #faf9fd;
  --mdc-protected-button-label-text-color: #005cbb;
  --mdc-protected-button-disabled-container-color: rgba(26, 27, 31, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: 0.006rem;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
  --mdc-filled-button-container-color: #005cbb;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-filled-button-disabled-container-color: rgba(26, 27, 31, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: 0.006rem;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-outlined-button-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-button-label-text-color: #005cbb;
  --mdc-outlined-button-outline-color: #74777f;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: 0.006rem;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
  --mat-text-button-state-layer-color: #005cbb;
  --mat-text-button-disabled-state-layer-color: #44474e;
  --mat-text-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
  --mat-protected-button-state-layer-color: #005cbb;
  --mat-protected-button-disabled-state-layer-color: #44474e;
  --mat-protected-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-disabled-state-layer-color: #44474e;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
  --mat-outlined-button-state-layer-color: #005cbb;
  --mat-outlined-button-disabled-state-layer-color: #44474e;
  --mat-outlined-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
  --mdc-icon-button-icon-color: #44474e;
  --mdc-icon-button-disabled-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
  --mat-icon-button-state-layer-color: #44474e;
  --mat-icon-button-disabled-state-layer-color: #44474e;
  --mat-icon-button-ripple-color: rgba(68, 71, 78, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: 0.006rem;
  --mdc-extended-fab-label-text-weight: 500;
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #d7e3ff;
  --mdc-fab-container-shape: 16px;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: #d7e3ff;
  --mdc-fab-small-container-shape: 12px;
  --mat-fab-foreground-color: #001b3f;
  --mat-fab-state-layer-color: #001b3f;
  --mat-fab-ripple-color: rgba(0, 27, 63, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(26, 27, 31, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
  --mat-fab-touch-target-display: block;
  --mat-fab-small-foreground-color: #001b3f;
  --mat-fab-small-state-layer-color: #001b3f;
  --mat-fab-small-ripple-color: rgba(0, 27, 63, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(26, 27, 31, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
  --mdc-snackbar-container-color: #2f3033;
  --mdc-snackbar-supporting-text-color: #f2f0f4;
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
  --mdc-snackbar-container-shape: 4px;
  --mat-snack-bar-button-color: #abc7ff;
  --mat-table-background-color: #faf9fd;
  --mat-table-header-headline-color: #1a1b1f;
  --mat-table-row-item-label-text-color: #1a1b1f;
  --mat-table-row-item-outline-color: #74777f;
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.006rem;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.016rem;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.016rem;
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
  --mdc-circular-progress-active-indicator-color: #005cbb;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 0.688rem;
  --mat-badge-text-weight: 500;
  --mat-badge-large-size-text-size: 0.688rem;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-line-height: 16px;
  --mat-badge-small-size-text-size: 0;
  --mat-badge-small-size-line-height: 6px;
  --mat-badge-large-size-line-height: 16px;
  --mat-bottom-sheet-container-text-color: #1a1b1f;
  --mat-bottom-sheet-container-background-color: #f4f3f6;
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: 0.031rem;
  --mat-bottom-sheet-container-text-weight: 400;
  --mat-bottom-sheet-container-shape: 28px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #1a1b1f;
  --mat-standard-button-toggle-state-layer-color: #1a1b1f;
  --mat-standard-button-toggle-selected-state-background-color: #dae2f9;
  --mat-standard-button-toggle-selected-state-text-color: #131c2b;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(26, 27, 31, 0.12);
  --mat-standard-button-toggle-divider-color: #74777f;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #005cbb;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #005cbb;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(26, 27, 31, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(26, 27, 31, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #44474e;
  --mat-datepicker-calendar-date-in-range-state-background-color: #d7e3ff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #e0e0ff;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #dae2f9;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #565e71;
  --mat-datepicker-toggle-icon-color: #44474e;
  --mat-datepicker-calendar-body-label-text-color: #1a1b1f;
  --mat-datepicker-calendar-period-button-text-color: #44474e;
  --mat-datepicker-calendar-period-button-icon-color: #44474e;
  --mat-datepicker-calendar-navigation-button-icon-color: #44474e;
  --mat-datepicker-calendar-header-text-color: #44474e;
  --mat-datepicker-calendar-date-today-outline-color: #005cbb;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-text-color: #1a1b1f;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #005cbb;
  --mat-datepicker-range-input-separator-color: #1a1b1f;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-container-background-color: #e9e7eb;
  --mat-datepicker-calendar-container-text-color: #1a1b1f;
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.875rem;
  --mat-datepicker-calendar-header-text-weight: 500;
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-divider-color: #74777f;
  --mat-divider-width: 1px;
  --mat-expansion-container-background-color: #faf9fd;
  --mat-expansion-container-text-color: #1a1b1f;
  --mat-expansion-actions-divider-color: #74777f;
  --mat-expansion-header-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-expansion-header-text-color: #1a1b1f;
  --mat-expansion-header-description-color: #44474e;
  --mat-expansion-header-indicator-color: #44474e;
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: 1.5rem;
  --mat-expansion-header-text-tracking: 0.009rem;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 1rem;
  --mat-expansion-container-text-tracking: 0.031rem;
  --mat-expansion-container-text-weight: 400;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
  --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --mat-icon-color: inherit;
  --mat-sidenav-container-background-color: #faf9fd;
  --mat-sidenav-container-text-color: #44474e;
  --mat-sidenav-content-background-color: #faf9fd;
  --mat-sidenav-content-text-color: #1a1b1f;
  --mat-sidenav-scrim-color: rgba(45, 48, 56, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
  --mat-stepper-header-icon-foreground-color: #faf9fd;
  --mat-stepper-header-selected-state-icon-background-color: #005cbb;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #005cbb;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
  --mat-stepper-container-color: #faf9fd;
  --mat-stepper-line-color: #74777f;
  --mat-stepper-header-hover-state-layer-color: rgba(47, 48, 51, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(47, 48, 51, 0.12);
  --mat-stepper-header-label-text-color: #44474e;
  --mat-stepper-header-optional-label-text-color: #44474e;
  --mat-stepper-header-selected-state-label-text-color: #44474e;
  --mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-icon-background-color: #44474e;
  --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #1a1b1f;
  --mat-toolbar-container-background-color: #faf9fd;
  --mat-toolbar-container-text-color: #1a1b1f;
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 1.75rem;
  --mat-toolbar-title-text-size: 1.375rem;
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-weight: 400;
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
  --mat-tree-container-background-color: #faf9fd;
  --mat-tree-node-text-color: #1a1b1f;
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 400;
  --mat-tree-node-min-height: 48px;
}
.mat-theme-loaded-marker {
  display: none;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/****************/
.mat-mdc-dialog-surface {
  border-radius: unset !important;
}

/***************/
.tool-bar-container {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-right: 10px;
}

.tool-bar-item {
  display: inline-block;
  text-align: center;
  margin: 2px;
  width: 40px;
  padding-top: 5px;
  height: 28px;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.11);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.tool-bar-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.21);
}

.tool-bar-icon {
  display: inline;
  vertical-align: middle;
  font-size: 22px;
  color: var(--primary-color-500);
}

.tool-bar-text {
  font-size: 11px;
  text-align: center;
  color: rgb(121, 121, 121);
}

.mat-mdc-dialog-container {
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 100% !important;
  min-width: auto !important;
}

.form-title {
  font-size: 14px;
  height: 20px;
  padding: 10px;
  border-bottom: lightgray;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  color: var(--white);
  background-color: var(--primary-color-500) !important;
  margin-bottom: 10px;
}

.form-subtitle {
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: lightgray;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  color: grey;
  border: none;
}

.form-input {
  font-size: 13px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.form-content {
  padding: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(188, 228, 243);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color-500);
}

.form-align-left {
  display: inline-block;
  width: 50%;
  text-align: left;
}

.form-align-right {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.form-label {
  font-size: 13px;
  color: darkgray;
  margin-right: 4px;
}

.form-value {
  font-size: 13px;
  color: rgb(54, 54, 54);
}

.form-divider {
  border-bottom: 1px solid lightgray;
}

.spacer {
  height: 24px;
}

.action_ok {
  display: inline-block;
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 45%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--primary-color-500);
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: width 0.5s;
  border-radius: 4px;
  margin: 5px;
  float: right;
}

.action_ok:hover {
  background-color: var(--primary-color-400);
}

.action_cancel {
  display: inline-block;
  text-align: center;
  width: 45%;
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--primary-color-800);
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-color-200);
  margin: 5px;
  float: left;
}

.action_cancel:hover {
  background-color: var(--gray-color-300);
}

.tuula-badge, .label-status-draft, .label-status-active {
  border-radius: 16px !important;
  padding: 2px 15px;
  mix-blend-mode: multiply;
  font-size: 10px;
  font-weight: 400;
  word-wrap: normal;
  font-family: Onest !important;
}

.label-status-active {
  background-color: var(--winter-wizard-color-50);
  border: 1px solid var(--winter-wizard-color-200);
  color: var(--winter-wizard-color-700);
}

.label-status-draft {
  background-color: var(--warning-color-50);
  color: var(--warning-color-700);
  border: 1px solid var(--warning-color-200);
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.A4 {
  width: 100%;
  margin: auto;
  max-width: 85%;
}

.froalaTableBlack thead tr th,
.froalaTableBlack tbody tr td {
  border-style: solid;
  border-color: black;
}

.froalaTableTransparent thead tr th,
.froalaTableTransparent tbody tr td {
  border-style: none;
}

.froalaTableOrange thead tr th,
.froalaTableOrange tbody tr td {
  border-style: solid;
  border-color: var(--primary-color-500);
}

.froalaTableBlue thead tr th,
.froalaTableBlue tbody tr td {
  border-style: solid;
  border-color: var(--primary-color-400);
}

/****Table***/
.mat-mdc-table {
  overflow: auto;
  font-family: var(--tuula-font-family) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: lightgrey !important;
}

.mat-mdc-cell,
.mat-mdc-header-cell,
.mat-mdc-footer-cell {
  padding-top: 0 !important;
}

.mat-mdc-header-cell {
  background-color: var(--gray-color-100) !important;
  color: var(--table-header-font-color) !important;
  font-size: 12px;
  font-weight: 500;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-cell {
  color: var(--form-font-color);
  font-family: var(--tuula-font-family) !important;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-sort-header {
  color: var(--table-header-font-color);
  padding-top: 5px;
}

.mat-mdc-header-row {
  min-height: 35px;
  border-color: var(--form-line-color);
  background-color: var(--table-header-bg-color);
}

.mat-mdc-row {
  background-color: var(--form-bg-color) !important;
  border-color: var(--light-border) !important;
  color: var(--form-font-color) !important;
}

.mat-mdc-row:hover {
  background-color: var(--light-border) !important;
}

.mat-mdc-row.highlighted {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-left-width: 5px !important;
  border-left-color: var(--primary-color-500) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}

.mat-mdc-row.highlighted-non-selection {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}

/****Table***/
.mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 14px !important;
  font-family: var(--tuula-font-family);
}

.mat-mdc-form-field-infix {
  --mat-form-field-container-height: 38px !important;
  --mat-form-field-filled-with-label-container-padding-top: 16px !important;
  --mat-form-field-filled-with-label-container-padding-bottom: 5px !important;
}

.mat-mdc-select-value {
  font-size: 13px !important;
}

.mat-mdc-option {
  font-size: 13px !important;
}

.mat-mdc-tooltip {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
  line-height: 15px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
  width: 250px !important;
}

/*scroll*/
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--white);
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-color-300);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-color-400);
}

.mat-mdc-form-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
.mat-mdc-form-field .mat-mdc-input-element {
  color: black;
  font-size: 13px;
}
.mat-mdc-form-field .mat-form-field-label {
  color: darkgray;
  font-size: 13px;
}
.mat-mdc-form-field .mat-form-field-underline {
  background-color: lightgrey;
}
.mat-mdc-form-field .mat-form-field-ripple {
  background-color: lightgrey;
}
.mat-mdc-form-field .mat-form-field-required-marker {
  color: lightgrey;
}

.mat-mdc-form-field.mat-focused {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: lightgray;
  font-size: 13px;
}
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--primary-color-500);
}
.mat-mdc-form-field.mat-focused .mat-form-field-required-marker {
  color: var(--primary-color-500);
}
.mat-mdc-form-field.mat-focused .mat-mdc-input-element {
  color: black;
  font-size: 13px;
}

.mat-mdc-tooltip {
  color: white;
  background-color: #5c5c5c;
  font-size: 11px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox .mat-checkbox-frame {
  border-color: lightgray;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--gray-color-400) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--primary-color-50) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: var(--mdc-filled-text-field-label-text-color, var(--mat-app-on-surface-variant)) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primary-color-500) !important;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: gray !important;
  background-color: gray !important;
  margin-top: -6px;
}

.cdk-overlay-pane {
  max-height: 100vh !important;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-handle-color: var(--white) !important;
  --mdc-switch-selected-pressed-handle-color: var(--white) !important;
  --mdc-switch-unselected-handle-color: var(--white) !important;
  --mdc-switch-unselected-focus-handle-color: var(--white) !important;
  --mdc-switch-unselected-hover-handle-color: var(--white) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-track-color: var(--primary-color-600) !important;
  --mdc-switch-unselected-focus-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-hover-track-color: var(--gray-color-100)!important;
  --mdc-switch-unselected-pressed-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-track-color: var(--gray-color-100) !important;
  --mdc-switch-selected-icon-color: var(--white) !important; /*tick color*/
  --mdc-switch-unselected-icon-color: var(--gray-color-100) !important; /*minus color*/
  --mat-switch-track-outline-color: var(--gray-color-300) !important;
  --mat-switch-disabled-unselected-track-outline-color: var(--gray-color-200) !important;
  --mat-switch-label-text-color: var(--gray-color-900) !important;
  --mdc-switch-track-width: 50px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-shape: 9999px;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mdc-switch-state-layer-size: 24px;
}

.mat-mdc-option.mdc-list-item {
  background-color: #fff !important;
}

.mat-mdc-option.mdc-list-item:hover {
  background-color: #f5f5f5 !important;
}